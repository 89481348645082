<template>
  <div class="container">
    <h1 class="titlePage titlePage_textLeft">NEW ORDER</h1>
    <customer-details></customer-details>
  </div>
</template>

<script>
  import { defineAsyncComponent } from 'vue'
  const CustomerDetails = defineAsyncComponent(() => import('./customerDetails/CustomerDetails'));

  export default {
    name: "CreateOrder",
    components: {
      CustomerDetails
    },
  }
</script>

<style scoped lang="scss">

</style>
